<template>
  <a-page-header @back="() => $router.go(-1)" title="PARÁMETROS DE INDICADOR" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Proyecto</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Parámetros de indicador</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="flex flex-wrap mb-4">
    <fieldset class="border border-solid w-full md:w-1/2 border-gray-300 p-3 px-8 lg:w-auto">
      <legend class="text-sm text-center">Filtros</legend>
      <ParametrosFiltros @fetchParametros="fetchParametros" :currentPage="currentPage" />
    </fieldset>

    <fieldset class="border border-solid w-full border-gray-300 p-3 px-8 md:w-1/2 lg:w-auto">
      <legend class="text-sm text-center">Acciones</legend>

      <button @click="handleAdd" class="text-center px-3">
        <div class="text-3xl text-green-500">
          <FileAddOutlined />
        </div>
        <p>Nuevo</p>
      </button>

      <button @click="handleEdit" class="text-center px-3" :disabled="isDisabled.edit">
        <div
          class="text-3xl"
          :class="[isDisabled.edit ? 'text-gray-400 cursor-not-allowed' : 'text-blue-400']"
        >
          <FormOutlined />
        </div>
        <p>Editar</p>
      </button>

      <button @click="handleDelete" class="text-center px-3" :disabled="isDisabled.deleteAndChange">
        <div
          class="text-3xl"
          :class="[
            isDisabled.deleteAndChange ? 'text-gray-400 cursor-not-allowed' : 'text-red-400'
          ]"
        >
          <DeleteOutlined />
        </div>
        <p>Eliminar</p>
      </button>

      <button
        @click="handleChangeState"
        class="text-center px-3"
        :disabled="isDisabled.deleteAndChange"
      >
        <div
          class="text-3xl"
          :class="[
            isDisabled.deleteAndChange ? 'text-gray-400 cursor-not-allowed' : 'text-yellow-400'
          ]"
        >
          <IssuesCloseOutlined />
        </div>
        <p>{{ parametro?.activo === "1" ? "Desactivar" : "Activar" }}</p>
      </button>
    </fieldset>
  </div>

  <!--------------- Tabla --------------->
  <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
    <a-table
      :row-selection="rowSelection"
      :columns="columns"
      :data-source="data"
      size="small"
      :pagination="false"
      :custom-row="customRow"
      rowKey="idparametro"
      :loading="isLoading"
      :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
    >
      <template #rangepages="{ index }">
        <span> {{ (currentPage - 1) * 10 + index + 1 }} </span>
      </template>

      <template #activo="{ text: activo }">
        <span>
          <a-tag :color="activo === '1' ? 'green' : 'volcano'">
            {{ activo === "1" ? "Activado" : "Desactivado" }}
          </a-tag>
        </span>
      </template>
    </a-table>
  </div>

  <footer class="flex flex-wrap items-center justify-between pb-6 pt-5 border-t-2">
    <!-- show-size-changer -> para agregar el @limit (numero de datos a traer) -->
    <div class="flex flex-wrap">
      <a-pagination
        v-model:pageSize="pageSize"
        v-model:current="currentPage"
        :total="totalDocs"
        :defaultCurrent="1"
        @change="onChangePage"
      />
      <!-- @showSizeChange="onShowSizeChange" -->

      <button class="ml-4" @click="fetchParametros">
        <ReloadOutlined />
      </button>
    </div>

    <p>Total de registros: {{ totalDocs }}</p>
  </footer>

  <!--------------- Modal--------------->
  <ParametrosAddEdit
    v-if="isVisible"
    :isVisible="isVisible"
    :parametro="parametro"
    @closeModal="closeModal"
    @fetchParametros="fetchParametros"
    @clearSelectedArrays="clearSelectedArrays"
  />
</template>

<script>
import { ref, watch, reactive, computed, toRaw, onMounted, createVNode } from "vue";
import { useStore } from "vuex";
import { columns } from "./utilsIndicadorParametro";
import ParametrosFiltros from "./IndicadorParametrosFiltros.vue";
import ParametrosAddEdit from "./IndicadorParametrosAddEdit.vue";
import IndicadorParametrosApi from "@/api/ind_parametro";
import { Modal } from "ant-design-vue";
import { useRoute } from "vue-router";
import {
  FileAddOutlined,
  FormOutlined,
  DeleteOutlined,
  ReloadOutlined,
  IssuesCloseOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons-vue";

export default {
  components: {
    ParametrosFiltros,
    FormOutlined,
    DeleteOutlined,
    ReloadOutlined,
    FileAddOutlined,
    ParametrosAddEdit,
    IssuesCloseOutlined
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const pageSize = ref(10);
    const totalDocs = ref(null);
    const isVisible = ref(false);
    const data = ref([]);
    const idindicador = ref("");
    const isLoading = ref(false);
    const parametro = ref(null);
    const state = reactive({
      selectedRowKeys: []
    });
    const isDisabled = reactive({
      edit: true,
      deleteAndChange: true
    });

    const currentPage = store.getters["parametros/currentPage"];

    idindicador.value = route.params?.indicador;

    // ------- trae datos de la api --------
    const fetchParametros = () => {
      const filter = store.getters["parametros/filter"];
      const search = store.getters["parametros/search"];
      const currentPage = store.getters["parametros/currentPage"];

      console.log({ currentPage, filter, search }, idindicador.value);

      isLoading.value = true;
      IndicadorParametrosApi.getAll({ currentPage, filter, search }, idindicador.value)
        .then((response) => {
          data.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    onMounted(() => {
      fetchParametros();
    });

    const rowSelection = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys;
          parametro.value = toRaw(selectedRows[0]);
        }
      };
    });

    // ------- desactiva la seleccion actual -------
    const customRow = () => {
      return {
        onDblclick: () => {
          clearSelectedRowkey();
        }
      };
    };

    const clearSelectedRowkey = () => {
      state.selectedRowKeys = [];
    };

    const onChangePage = (newPage) => {
      store.dispatch("parametros/setCurrentPage", newPage);
      fetchParametros();
      clearSelectedArrays();
    };

    watch(state, () => {
      isDisabled.edit = state.selectedRowKeys.length !== 1;
      isDisabled.deleteAndChange = !state.selectedRowKeys.length;
    });

    // ------- mostrar modal -------
    const showModal = () => {
      isVisible.value = true;
    };

    const closeModal = () => {
      isVisible.value = false;
    };

    // ------- acciones -------
    const handleAdd = () => {
      parametro.value = {
        clave: "",
        valor: "",
        descripcion: "",
        idindicador: idindicador.value
      };
      showModal();
    };

    const handleEdit = () => {
      showModal();
    };

    const handleChangeState = () => {
      const activateMsg = parametro.value.activo === "1" ? "desactivará" : "activará";

      Modal.confirm({
        title: () => "¿Estás seguro de cambiar el estado?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => `Se ${activateMsg} el parametro ${parametro.value.clave}`,
        okText: () => "Sí, cambiar",
        okType: "danger",
        cancelText: () => "Cancelar",

        onOk() {
          const payload = {
            ids: toRaw(state.selectedRowKeys),
            activos: [parametro.value.activo === "1" ? "0" : "1"]
          };

          IndicadorParametrosApi.changeStateMany(payload)
            .then(() => {
              fetchParametros();
              clearSelectedArrays();
            })
            .catch((err) => console.log(err));
        }
      });
    };

    const clearSelectedArrays = () => {
      state.selectedRowKeys = [];
    };

    const handleDelete = () => {
      console.log(parametro.value);
      Modal.confirm({
        title: () => "¿Estás seguro de eliminar?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => `Se eliminará el parámetro ${parametro.value.clave}`,
        okText: () => "Sí, eliminar",
        okType: "danger",
        cancelText: () => "Cancelar",
        onOk() {
          console.log(state.selectedRowKeys);

          IndicadorParametrosApi.deleteMany(toRaw(state.selectedRowKeys))
            .then(() => {
              fetchParametros();
              clearSelectedArrays();
            })
            .catch((err) => console.log(err));
        }
      });
    };

    return {
      isLoading,
      data,
      columns,
      customRow,
      rowSelection,
      handleAdd,
      handleEdit,
      handleDelete,
      handleChangeState,
      pageSize,
      currentPage,
      totalDocs,
      isVisible,
      closeModal,
      fetchParametros,
      parametro,
      isDisabled,
      clearSelectedArrays,
      onChangePage
    };
  }
};
</script>

<style></style>
