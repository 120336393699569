import { post, put } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";
const API_SERVICE = "/api/sivi_data/V1";
const API_SERVICE_ARRAY = "/api/sivi_data/ServiceArray/V1";

export default {
  getAll: (payload, idindicador) => {
    var { filter, search } = payload;
    if (payload.filter == "all") filter = "";

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_ind_parametro",
          type: "string"
        },
        {
          name: "@page",
          value: payload.currentPage,
          type: "int"
        },
        {
          name: "@filtros_AND",
          value: "activo|idindicador",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${filter}%|${idindicador}`,
          type: "string"
        },
        {
          name: "@filtros_OR",
          value: "clave|valor",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  addOne: (payload) => {
    const request = {
      _type: 2,
      _table: "ind_parametro",
      _petitions: [
        {
          name: "clave",
          value: payload.clave,
          type: "string"
        },
        {
          name: "valor",
          value: payload.valor,
          type: "string"
        },
        {
          name: "idindicador",
          value: payload.idindicador,
          type: "string"
        },
        {
          name: "activo",
          value: "1",
          type: "int"
        }
      ]
    };

    if (payload.descripcion) {
      request._petitions.push({
        name: "descripcion",
        value: payload.descripcion,
        type: "string"
      });
    }

    return post(API_SERVICE, request);
  },

  editOne: (payload, idparametro) => {
    const request = {
      _type: 2,
      _table: "ind_parametro",
      _petitions: [
        {
          name: "clave",
          value: payload.clave,
          type: "string"
        },
        {
          name: "valor",
          value: payload.valor,
          type: "string"
        },
        {
          name: "activo",
          value: payload.activo,
          type: "int"
        }
      ]
    };

    if (payload.descripcion) {
      request._petitions.push({
        name: "descripcion",
        value: payload.descripcion,
        type: "string"
      });
    }

    return put(API_SERVICE + "/" + idparametro, request);
  },

  deleteMany: (payload) => {
    const request = {
      _type: 3,
      _table: "ind_parametro",
      _petitions: [
        {
          name: "@ids",
          value: payload.join("|"),
          type: "string"
        },
        {
          name: "eliminado",
          value: payload.map(() => "1").join("|"),
          type: "string"
        }
      ]
    };

    return put(API_SERVICE_ARRAY, request);
  },
  changeStateMany: (payload) => {
    const request = {
      _type: 3,
      _table: "ind_parametro",
      _petitions: [
        {
          name: "@ids",
          value: payload.ids.join("|"),
          type: "string"
        },
        {
          name: "activo",
          value: payload.activos.join("|"),
          type: "string"
        }
      ]
    };
    return put(API_SERVICE_ARRAY, request);
  }
};
