<template>
  <a-modal
    :visible="true"
    :title="[isEdit ? 'Editar parámetro' : 'Nuevo parámetro']"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="closeModal"
    class="max-w-md"
    okText="Guardar"
    :maskClosable="false"
  >
    <a-form :model="formState" :rules="rules" ref="formRef" v-bind="layout">
      <a-form-item label="Nombre" name="clave">
        <a-input v-model:value="formState.clave" placeholder="ejm. PI" />
      </a-form-item>
      <a-form-item label="Valor" name="valor">
        <!-- <a-input v-model:value="formState.valor" /> -->
        <a-textarea v-model:value="formState.valor" placeholder="ejm. 3.1416" />
      </a-form-item>
      <a-form-item label="Descripción" name="descripcion">
        <a-textarea
          v-model:value="formState.descripcion"
          placeholder="Definición del parámetro"
          :rows="4"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, toRaw, ref } from "vue";
import { notification } from "ant-design-vue";
import ParametrosApi from "@/api/ind_parametro";

export default {
  props: {
    parametro: {
      type: Object
    }
  },
  emits: ["closeModal", "fetchParametros", "clearSelectedArrays"],
  setup(props, { emit }) {
    const formRef = ref();
    const formState = reactive(props.parametro);
    // eslint-disable-next-line vue/no-setup-props-destructure
    const parametroStatic = props.parametro;

    const isEdit = !!parametroStatic.idparametro;

    const closeModal = () => {
      emit("closeModal");
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);
          let response = {};
          let msgNotificacion = "";
          try {
            if (isEdit) {
              const isUnique = parametroStatic.denominacion !== formState.denominacion;

              const idparametro = toRaw(formState)?.idparametro;

              response = await ParametrosApi.editOne(payload, idparametro, isUnique);
              msgNotificacion = "El parámetro del indicador ha sido modificado correctamente.";
            } else {
              response = await ParametrosApi.addOne(payload);
              msgNotificacion = "El parámetro del indicador ha sido creado satisfactoriamente.";
            }

            if (response.status == 200) {
              notification.success({
                message: "Operación exitosa",
                description: msgNotificacion
              });
            }
            emit("closeModal");
            emit("fetchParametros");
          } catch (error) {
            notification.error({
              message: "Operación inválida",
              description: error.message
            });
          }
        })
        .catch((err) => console.log(err));
    };

    const handleCancel = () => {
      emit("clearSelectedArrays");
      emit("fetchParametros");
      emit("closeModal");
    };

    const rules = {
      clave: [
        {
          required: true,
          message: "El campo nombre no puede estar vacío",
          trigger: "blur"
        },
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ],
      valor: [
        {
          required: true,
          message: "El campo valor no puede estar vacío",
          trigger: "blur"
        },
        {
          max: 350,
          message: "La cantidad máxima de caracteres es de 350",
          trigger: "blur"
        }
      ]
    };

    const layout = {
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 18,
        offset: 1
      }
    };
    return {
      handleOk,
      closeModal,
      handleCancel,
      formState,
      formRef,
      layout,
      rules,
      isEdit
    };
  }
};
</script>
