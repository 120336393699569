export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "NOMBRE",
    dataIndex: "clave",
    key: "clave",
    width: 220
  },
  {
    title: "VALOR",
    dataIndex: "valor",
    key: "valor",
    ellipsis: true,
    width: 220
  },
  {
    title: "DESCRIPCIÓN",
    dataIndex: "descripcion",
    key: "descripcion",
    ellipsis: true,
    width: 220
  },
  {
    title: "ESTADO",
    dataIndex: "activo",
    key: "activo",
    slots: { customRender: "activo" },
    width: 120
  }
];

export const data = [
  {
    key: "98",
    nombre: "VARIABLE",
    valor: "123456",
    modulo: "Import. Export.",
    idmodulosistema: "4",
    activo: "1"
  },
  {
    key: "96",
    nombre: "TIEMPO_CARGA",
    valor: "10",
    modulo: "Configuración",
    idmodulosistema: "3",
    activo: "1"
  },
  {
    key: "95",
    nombre: "TRADUCTOR333",
    valor: "45",
    modulo: "Configuración",
    idmodulosistema: "3",
    activo: "1"
  },
  {
    key: "94",
    nombre: "TRADUCTORd",
    valor: "45",
    modulo: "Configuración",
    idmodulosistema: "3",
    activo: "1"
  },
  {
    key: "93",
    nombre: "PRUEBApp",
    valor: "123456",
    modulo: "Configuración",
    idmodulosistema: "3",
    activo: "1"
  },
  {
    key: "92",
    nombre: "DATOS",
    valor: "valor",
    modulo: "Configuración",
    idmodulosistema: "3",
    activo: "1"
  },
  {
    key: "91",
    nombre: "ÑANDÚo",
    valor: "ÑIÑO#d",
    modulo: "Import. Export.",
    idmodulosistema: "4",
    activo: "1"
  },
  {
    key: "90",
    nombre: "ÑÚ",
    valor: "ÑOÑO",
    modulo: "Configuración",
    idmodulosistema: "3",
    activo: "0"
  },
  {
    key: "89",
    nombre: "ÑÚ",
    valor: "ÑATA",
    modulo: "Perfil",
    idmodulosistema: "2",
    activo: "1"
  },
  {
    key: "88",
    nombre: "ÑÚ",
    valor: "ÑIÑO",
    modulo: "Perfil",
    idmodulosistema: "2",
    activo: "1"
  },
  {
    key: "87",
    nombre: "COLORES",
    valor: "$ÑOÑO",
    modulo: "Perfil",
    idmodulosistema: "2",
    activo: "1"
  },
  {
    key: "86",
    nombre: "PERFIL",
    valor: "parametros",
    modulo: "Perfil",
    idmodulosistema: "2",
    activo: "1"
  },
  {
    key: "76",
    nombre: "PRUEBA",
    valor: "marronD",
    modulo: "Configuración",
    idmodulosistema: "3",
    activo: "1"
  },
  {
    key: "74",
    nombre: "PRUEBA",
    valor: "secret3",
    modulo: "Configuración",
    idmodulosistema: "3",
    activo: "1"
  },
  {
    key: "73",
    nombre: "ALGO",
    valor: "secret",
    modulo: "Perfil",
    idmodulosistema: "2",
    activo: "1"
  },
  {
    key: "66",
    nombre: "CONFIGURACION",
    valor: "ALGO",
    modulo: "Perfil",
    idmodulosistema: "2",
    activo: "1"
  },
  {
    key: "65",
    nombre: "PRUEBA",
    valor: "secret",
    modulo: "Import. Export.",
    idmodulosistema: "4",
    activo: "0"
  },
  {
    key: "64",
    nombre: "Datos2",
    valor: "secret",
    modulo: "Inicio",
    idmodulosistema: "1",
    activo: "1"
  },
  {
    key: "59",
    nombre: "PRUEBA2",
    valor: "que paso",
    modulo: "Perfil",
    idmodulosistema: "2",
    activo: "0"
  },
  {
    key: "48",
    nombre: "PRACTICA",
    valor: "algoooasdsada",
    modulo: "Perfil",
    idmodulosistema: "2",
    activo: "0"
  },
  {
    key: "44",
    nombre: "TRADUCTOR",
    valor: "ingles",
    modulo: "Inicio",
    idmodulosistema: "1",
    activo: "1"
  },
  {
    key: "37",
    nombre: "TIME_VALIDACION",
    valor: "5.00",
    modulo: "Inicio",
    idmodulosistema: "1",
    activo: "1"
  }
];
